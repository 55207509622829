import React, {useContext, useEffect, useState} from "react";
import Avatar from "../../../ui/Avatar/Avatar";
import {Link} from "react-router-dom";
import Button from "../../../ui/Button/Button";
import {CommentsContext} from "./CommentSection";
import store from "../../../store";
import classNames from "classnames";
import getDateFromSQL from "../../../utils/getDateFromSQL";
import CommentList from "./CommentList";
import {CommentInput} from "./CommentInput";
import {FaHeart, FaRegHeart} from "react-icons/fa";

export const Comment = ({comment, depth = 0}) => {
  const {getReplies, getRepliesCount, sendComment, deleteComment, likeComment} = useContext(CommentsContext);
  const childComments = getReplies(comment.id);
  const [expanded, setExpanded] = useState(true);
  const [replyOpened, setReplyOpened] = useState(false);
  const [liked, setLiked] = useState(comment?.user_like);
  const [likesCount, setLikesCount] = useState(Number(comment?.likes_count));

  function handleCommentLike() {
    if (!store.auth) return;
    if (liked) {
      setLiked(false);
      setLikesCount(s => s - 1);
    } else {
      setLiked(true);
      setLikesCount(s => s + 1);
    }

    likeComment(comment);
  }

  useEffect(() => {
    if (depth % 7 === 0 && getRepliesCount(comment.id) > 0) setExpanded(false);
  }, []);
  useEffect(() => {
    if (!expanded) {
      setExpanded(true);
    }
  }, [replyOpened]);

  // useLayoutEffect(() => {
  //
  //   if (!expanded) {
  //     const target = document.querySelector(`[data-id="${comment.id}"]`);
  //     target.scrollIntoView({
  //       behavior: "smooth",
  //       block: 'center',
  //       inline: 'center',
  //     });
  //   }
  // }, [expanded]);
  return (
    <>
      <div data-id={comment.id} className={`flex flex-col gap-2 flex-1`}>
        <div className="flex p-2 bg-back shadow rounded-lg items-start justify-between">
          <div className="flex gap-2 flex-1 relative  w-full">
            <Link to={`/user/${comment.user_id}`}>
              <Avatar src={comment.user_avatar_url} size="normal"/>
            </Link>
            <div className="flex flex-col flex-1 items-stretch overflow-hidden">
              <div className="flex flex-wrap items-center gap-2">
                <Link
                  to={`/user/${comment.user_id}`}
                  className="font-semibold text-base cursor-pointer hover:text-primary"
                >
                  {comment.user_nickname}
                </Link>

              </div>
              <div className="break-words">{comment.body}</div>
              <div className={"flex justify-between items-center"}>
                <div className={"flex gap-2"}>
                  {store.auth &&
                    <button tabIndex={0} className={"text-primary hover:underline cursor-pointer select-none"}
                            onClick={() => setReplyOpened(s => !s)}>Ответить</button>}
                  {store.auth && store.user?.id === comment.user_id && (
                    <button tabIndex={0} className={"text-danger hover:underline cursor-pointer select-none"}
                            onClick={() => deleteComment(comment.id)}>Удалить</button>)}
                </div>
                <button
                  tabIndex={0}
                  className={classNames("flex items-center gap-1 cursor-pointer select-none", liked ? "text-like hover:text-danger" : "text-primary hover:text-primary-darker")}
                  onClick={handleCommentLike}>
                  {liked
                    ?
                    <FaHeart className={"mt-0.5"} size={"16px"}/>
                    :
                    <FaRegHeart className={"mt-0.5"} size={"16px"}/>
                  }
                  {likesCount}
                </button>

              </div>
            </div>
            <span className="font-light text-xs absolute top-0 right-0">
              {getDateFromSQL(comment.created_at)}
            </span>
          </div>


        </div>
      </div>
      {replyOpened && expanded &&
        <div className={"flex"}>
          <div className={"pl-2 md:pl-4"}></div>
          <CommentInput parent={comment} onSend={(data) => {
            sendComment(data);
            setReplyOpened(false);
          }}/>
        </div>
      }
      {childComments?.length && expanded &&
        <div
          className={classNames("relative", depth % 7 === 0 && depth > 0 && "-ml-14 md:-ml-28 py-4 -mt-3 border-y-2 border-secondary-darker bg-back-darker ")}>
          <div
            className={"absolute top-0 left-0 pl-2 md:pl-4 h-full border-l-2 border-secondary-darker hover:border-primary cursor-pointer"}
            onClick={() => setExpanded(false)}/>
          <div className={"pl-2 md:pl-4"}>
            <CommentList comments={childComments} depth={depth + 1}/>
          </div>
        </div>}
      {!expanded &&
        <Button variant={"outlined"} onClick={() => setExpanded(true)}>Показать ответы
          ({getRepliesCount(comment.id)})</Button>
      }
    </>
  );
};

