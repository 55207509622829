export const countriesListRus = [
  {"title": "Австралия", "value": "AU"}, {"title": "Австрия", "value": "AT"}, {
    "title": "Азербайджан",
    "value": "AZ",
  }, {"title": "Аландские о-ва", "value": "AX"}, {"title": "Албания", "value": "AL"}, {
    "title": "Алжир",
    "value": "DZ",
  }, {"title": "Американское Самоа", "value": "AS"}, {"title": "Ангилья", "value": "AI"}, {
    "title": "Ангола",
    "value": "AO",
  }, {"title": "Андорра", "value": "AD"}, {"title": "Антарктида", "value": "AQ"}, {
    "title": "Антигуа и Барбуда",
    "value": "AG",
  }, {"title": "Аргентина", "value": "AR"}, {"title": "Армения", "value": "AM"}, {
    "title": "Аруба",
    "value": "AW",
  }, {"title": "Афганистан", "value": "AF"}, {"title": "Багамы", "value": "BS"}, {
    "title": "Бангладеш",
    "value": "BD",
  }, {"title": "Барбадос", "value": "BB"}, {"title": "Бахрейн", "value": "BH"}, {
    "title": "Беларусь",
    "value": "BY",
  }, {"title": "Белиз", "value": "BZ"}, {"title": "Бельгия", "value": "BE"}, {
    "title": "Бенин",
    "value": "BJ",
  }, {"title": "Бермудские о-ва", "value": "BM"}, {"title": "Болгария", "value": "BG"}, {
    "title": "Боливия",
    "value": "BO",
  }, {"title": "Бонэйр, Синт-Эстатиус и Саба", "value": "BQ"}, {
    "title": "Босния и Герцеговина",
    "value": "BA",
  }, {"title": "Ботсвана", "value": "BW"}, {
    "title": "Бразилия",
    "value": "BR",
  }, {"title": "Британская территория в Индийском океане", "value": "IO"}, {
    "title": "Бруней-Даруссалам",
    "value": "BN",
  }, {"title": "Буркина-Фасо", "value": "BF"}, {"title": "Бурунди", "value": "BI"}, {
    "title": "Бутан",
    "value": "BT",
  }, {"title": "Вануату", "value": "VU"}, {"title": "Ватикан", "value": "VA"}, {
    "title": "Великобритания",
    "value": "GB",
  }, {"title": "Венгрия", "value": "HU"}, {
    "title": "Венесуэла",
    "value": "VE",
  }, {"title": "Виргинские о-ва (Великобритания)", "value": "VG"}, {
    "title": "Виргинские о-ва (США)",
    "value": "VI",
  }, {"title": "Внешние малые о-ва (США)", "value": "UM"}, {
    "title": "Восточный Тимор",
    "value": "TL",
  }, {"title": "Вьетнам", "value": "VN"}, {"title": "Габон", "value": "GA"}, {
    "title": "Гаити",
    "value": "HT",
  }, {"title": "Гайана", "value": "GY"}, {"title": "Гамбия", "value": "GM"}, {
    "title": "Гана",
    "value": "GH",
  }, {"title": "Гваделупа", "value": "GP"}, {"title": "Гватемала", "value": "GT"}, {
    "title": "Гвинея",
    "value": "GN",
  }, {"title": "Гвинея-Бисау", "value": "GW"}, {"title": "Германия", "value": "DE"}, {
    "title": "Гернси",
    "value": "GG",
  }, {"title": "Гибралтар", "value": "GI"}, {"title": "Гондурас", "value": "HN"}, {
    "title": "Гонконг (САР)",
    "value": "HK",
  }, {"title": "Гренада", "value": "GD"}, {"title": "Гренландия", "value": "GL"}, {
    "title": "Греция",
    "value": "GR",
  }, {"title": "Грузия", "value": "GE"}, {"title": "Гуам", "value": "GU"}, {
    "title": "Дания",
    "value": "DK",
  }, {"title": "Джерси", "value": "JE"}, {"title": "Джибути", "value": "DJ"}, {
    "title": "Доминика",
    "value": "DM",
  }, {"title": "Доминиканская Республика", "value": "DO"}, {"title": "Египет", "value": "EG"}, {
    "title": "Замбия",
    "value": "ZM",
  }, {"title": "Западная Сахара", "value": "EH"}, {"title": "Зимбабве", "value": "ZW"}, {
    "title": "Израиль",
    "value": "IL",
  }, {"title": "Индия", "value": "IN"}, {"title": "Индонезия", "value": "ID"}, {
    "title": "Иордания",
    "value": "JO",
  }, {"title": "Ирак", "value": "IQ"}, {"title": "Иран", "value": "IR"}, {
    "title": "Ирландия",
    "value": "IE",
  }, {"title": "Исландия", "value": "IS"}, {"title": "Испания", "value": "ES"}, {
    "title": "Италия",
    "value": "IT",
  }, {"title": "Йемен", "value": "YE"}, {"title": "Кабо-Верде", "value": "CV"}, {
    "title": "Казахстан",
    "value": "KZ",
  }, {"title": "Камбоджа", "value": "KH"}, {"title": "Камерун", "value": "CM"}, {
    "title": "Канада",
    "value": "CA",
  }, {"title": "Катар", "value": "QA"}, {"title": "Кения", "value": "KE"}, {
    "title": "Кипр",
    "value": "CY",
  }, {"title": "Киргизия", "value": "KG"}, {"title": "Кирибати", "value": "KI"}, {
    "title": "Китай",
    "value": "CN",
  }, {"title": "КНДР", "value": "KP"}, {"title": "Кокосовые о-ва", "value": "CC"}, {
    "title": "Колумбия",
    "value": "CO",
  }, {"title": "Коморы", "value": "KM"}, {"title": "Конго - Браззавиль", "value": "CG"}, {
    "title": "Конго - Киншаса",
    "value": "CD",
  }, {"title": "Коста-Рика", "value": "CR"}, {"title": "Кот-д’Ивуар", "value": "CI"}, {
    "title": "Куба",
    "value": "CU",
  }, {"title": "Кувейт", "value": "KW"}, {"title": "Кюрасао", "value": "CW"}, {
    "title": "Лаос",
    "value": "LA",
  }, {"title": "Латвия", "value": "LV"}, {"title": "Лесото", "value": "LS"}, {
    "title": "Либерия",
    "value": "LR",
  }, {"title": "Ливан", "value": "LB"}, {"title": "Ливия", "value": "LY"}, {
    "title": "Литва",
    "value": "LT",
  }, {"title": "Лихтенштейн", "value": "LI"}, {"title": "Люксембург", "value": "LU"}, {
    "title": "Маврикий",
    "value": "MU",
  }, {"title": "Мавритания", "value": "MR"}, {"title": "Мадагаскар", "value": "MG"}, {
    "title": "Майотта",
    "value": "YT",
  }, {"title": "Макао (САР)", "value": "MO"}, {"title": "Малави", "value": "MW"}, {
    "title": "Малайзия",
    "value": "MY",
  }, {"title": "Мали", "value": "ML"}, {"title": "Мальдивы", "value": "MV"}, {
    "title": "Мальта",
    "value": "MT",
  }, {"title": "Марокко", "value": "MA"}, {"title": "Мартиника", "value": "MQ"}, {
    "title": "Маршалловы Острова",
    "value": "MH",
  }, {"title": "Мексика", "value": "MX"}, {"title": "Мозамбик", "value": "MZ"}, {
    "title": "Молдова",
    "value": "MD",
  }, {"title": "Монако", "value": "MC"}, {"title": "Монголия", "value": "MN"}, {
    "title": "Монтсеррат",
    "value": "MS",
  }, {"title": "Мьянма (Бирма)", "value": "MM"}, {"title": "Намибия", "value": "NA"}, {
    "title": "Науру",
    "value": "NR",
  }, {"title": "Непал", "value": "NP"}, {"title": "Нигер", "value": "NE"}, {
    "title": "Нигерия",
    "value": "NG",
  }, {"title": "Нидерланды", "value": "NL"}, {"title": "Никарагуа", "value": "NI"}, {
    "title": "Ниуэ",
    "value": "NU",
  }, {"title": "Новая Зеландия", "value": "NZ"}, {"title": "Новая Каледония", "value": "NC"}, {
    "title": "Норвегия",
    "value": "NO",
  }, {"title": "о-в Буве", "value": "BV"}, {"title": "о-в Мэн", "value": "IM"}, {
    "title": "о-в Норфолк",
    "value": "NF",
  }, {"title": "о-в Рождества", "value": "CX"}, {"title": "о-в Св. Елены", "value": "SH"}, {
    "title": "о-ва Питкэрн",
    "value": "PN",
  }, {"title": "о-ва Тёркс и Кайкос", "value": "TC"}, {
    "title": "о-ва Херд и Макдональд",
    "value": "HM",
  }, {"title": "ОАЭ", "value": "AE"}, {"title": "Оман", "value": "OM"}, {
    "title": "Острова Кайман",
    "value": "KY",
  }, {"title": "Острова Кука", "value": "CK"}, {"title": "Пакистан", "value": "PK"}, {
    "title": "Палау",
    "value": "PW",
  }, {"title": "Палестинские территории", "value": "PS"}, {
    "title": "Панама",
    "value": "PA",
  }, {"title": "Папуа — Новая Гвинея", "value": "PG"}, {"title": "Парагвай", "value": "PY"}, {
    "title": "Перу",
    "value": "PE",
  }, {"title": "Польша", "value": "PL"}, {"title": "Португалия", "value": "PT"}, {
    "title": "Пуэрто-Рико",
    "value": "PR",
  }, {"title": "Республика Корея", "value": "KR"}, {"title": "Реюньон", "value": "RE"}, {
    "title": "Россия",
    "value": "RU",
  }, {"title": "Руанда", "value": "RW"}, {"title": "Румыния", "value": "RO"}, {
    "title": "Сальвадор",
    "value": "SV",
  }, {"title": "Самоа", "value": "WS"}, {"title": "Сан-Марино", "value": "SM"}, {
    "title": "Сан-Томе и Принсипи",
    "value": "ST",
  }, {"title": "Саудовская Аравия", "value": "SA"}, {
    "title": "Северная Македония",
    "value": "MK",
  }, {"title": "Северные Марианские о-ва", "value": "MP"}, {
    "title": "Сейшельские Острова",
    "value": "SC",
  }, {"title": "Сен-Бартелеми", "value": "BL"}, {"title": "Сен-Мартен", "value": "MF"}, {
    "title": "Сен-Пьер и Микелон",
    "value": "PM",
  }, {"title": "Сенегал", "value": "SN"}, {
    "title": "Сент-Винсент и Гренадины",
    "value": "VC",
  }, {"title": "Сент-Китс и Невис", "value": "KN"}, {"title": "Сент-Люсия", "value": "LC"}, {
    "title": "Сербия",
    "value": "RS",
  }, {"title": "Сингапур", "value": "SG"}, {"title": "Синт-Мартен", "value": "SX"}, {
    "title": "Сирия",
    "value": "SY",
  }, {"title": "Словакия", "value": "SK"}, {"title": "Словения", "value": "SI"}, {
    "title": "Соединенные Штаты",
    "value": "US",
  }, {"title": "Соломоновы Острова", "value": "SB"}, {"title": "Сомали", "value": "SO"}, {
    "title": "Судан",
    "value": "SD",
  }, {"title": "Суринам", "value": "SR"}, {"title": "Сьерра-Леоне", "value": "SL"}, {
    "title": "Таджикистан",
    "value": "TJ",
  }, {"title": "Таиланд", "value": "TH"}, {"title": "Тайвань", "value": "TW"}, {
    "title": "Танзания",
    "value": "TZ",
  }, {"title": "Того", "value": "TG"}, {"title": "Токелау", "value": "TK"}, {
    "title": "Тонга",
    "value": "TO",
  }, {"title": "Тринидад и Тобаго", "value": "TT"}, {"title": "Тувалу", "value": "TV"}, {
    "title": "Тунис",
    "value": "TN",
  }, {"title": "Туркменистан", "value": "TM"}, {"title": "Турция", "value": "TR"}, {
    "title": "Уганда",
    "value": "UG",
  }, {"title": "Узбекистан", "value": "UZ"}, {"title": "Украина", "value": "UA"}, {
    "title": "Уоллис и Футуна",
    "value": "WF",
  }, {"title": "Уругвай", "value": "UY"}, {
    "title": "Фарерские о-ва",
    "value": "FO",
  }, {"title": "Федеративные Штаты Микронезии", "value": "FM"}, {
    "title": "Фиджи",
    "value": "FJ",
  }, {"title": "Филиппины", "value": "PH"}, {"title": "Финляндия", "value": "FI"}, {
    "title": "Фолклендские о-ва",
    "value": "FK",
  }, {"title": "Франция", "value": "FR"}, {
    "title": "Французская Гвиана",
    "value": "GF",
  }, {"title": "Французская Полинезия", "value": "PF"}, {
    "title": "Французские Южные территории",
    "value": "TF",
  }, {"title": "Хорватия", "value": "HR"}, {
    "title": "Центрально-Африканская Республика",
    "value": "CF",
  }, {"title": "Чад", "value": "TD"}, {"title": "Черногория", "value": "ME"}, {
    "title": "Чехия",
    "value": "CZ",
  }, {"title": "Чили", "value": "CL"}, {"title": "Швейцария", "value": "CH"}, {
    "title": "Швеция",
    "value": "SE",
  }, {"title": "Шпицберген и Ян-Майен", "value": "SJ"}, {"title": "Шри-Ланка", "value": "LK"}, {
    "title": "Эквадор",
    "value": "EC",
  }, {"title": "Экваториальная Гвинея", "value": "GQ"}, {"title": "Эритрея", "value": "ER"}, {
    "title": "Эсватини",
    "value": "SZ",
  }, {"title": "Эстония", "value": "EE"}, {
    "title": "Эфиопия",
    "value": "ET",
  }, {"title": "Южная Георгия и Южные Сандвичевы о-ва", "value": "GS"}, {
    "title": "Южно-Африканская Республика",
    "value": "ZA",
  }, {"title": "Южный Судан", "value": "SS"}, {"title": "Ямайка", "value": "JM"}, {"title": "Япония", "value": "JP"},
];